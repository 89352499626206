import { Box, Grid, Typography } from "@mui/material";
import React from "react";

import TransmissionGrid from "./TransmissionGrid";
import { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../AuthContext";
import {
  getTransmissionDetailsByDealer,
  getTransmissionDetailsHistoryByDealer,
} from "../services/dealerServices";
import { useEffect } from "react";
import TransmissionDetails from "./TransmissionDetails";

const TransmissionDetailsList = ({
  showDealer = false,
  displayType,
  dealer,
}) => {
  //console.log("loading transmission details for :", displayType);
  const { group } = useContext(AuthContext);
  const [transmissionDetailsListData, setTransmissionDetailsListData] =
    useState([]);
  const [logdata, setLogdata] = useState(null);
  const [disType, setDisType] = useState(displayType);
  const [selectedDealer, setSelectedDealer] = useState(dealer);
  // useEffect(() => {
  //   setDisType(displayType);
  //   setSelectedDealer(dealer);
  // }, [displayType, dealer]);

  const getTransmissionListData = (dealerId) => {
    //   console.log("dealer id :", dealerId);
    getTransmissionDetailsByDealer(dealerId)
      .then(
        (res) => {
          //        console.log("transmission data :", res.data.body);
          const data = res.data;
          const statusCode = res.status;
          if (statusCode === 200 && data.length > 0) {
            setTransmissionDetailsListData(data);
            setLogdata(data[0]);
          }
        },
        (err) => {
          console.log("Unable retrive Transmission List data", err);
        }
      )
      .catch((err) => {
        console.error("Transmission List service Error :", err);
      });
  };

  const getTransmissionHistoryListData = (dealerId) => {
    //  console.log("dealer id :", dealerId);
    getTransmissionDetailsHistoryByDealer(dealerId)
      .then(
        (res) => {
          //console.log("transmission History data :", res.data.body);
          const data = res.data;
          const statusCode = res.status;
          if (statusCode === 200 && data.length > 0) {
            setTransmissionDetailsListData(data);
            setLogdata(data[0]);
          }
        },
        (err) => {
          console.log("Unable retrive Transmission List data", err);
        }
      )
      .catch((err) => {
        console.error("Transmission List service Error :", err);
      });
  };

  useEffect(() => {
    const dealerId = selectedDealer ? selectedDealer.dealerId : 0;
    if (disType === "admin") {
      getTransmissionListData(0);
    } else {
      getTransmissionHistoryListData(dealerId);
    }
  }, [selectedDealer, group, disType]);

  const handlelogclicked = (param) => {
    console.log("selected Row Log :", param);
    setLogdata(param);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={6} sm={8} lg={8}>
        <Box
          sx={{
            maxWidth: "100%",
            padding: 2,
            boxShadow: 4,
            borderRadius: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            backgroundColor: "#fff",
          }}
        >
          <Grid container>
            <Grid item xs={12} sm={12} lg={12}>
              <Box
                sx={{
                  maxWidth: "100%",
                  padding: 1,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Typography variant="h6" component="h6">
                  Transmission Details <br />
                </Typography>
                <Box sx={{ flexGrow: 1 }} />
              </Box>
              <Box
                sx={{
                  maxWidth: "100%",
                  padding: 1,
                  display: "flex",
                  flexDirection: "row",
                  maxHeight: "50vh",
                }}
              >
                <TransmissionGrid
                  transmissionListData={transmissionDetailsListData}
                  showDealer={showDealer}
                  onlogclicked={handlelogclicked}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6} sm={4} lg={4}>
        <TransmissionDetails logdata={logdata} />
      </Grid>
    </Grid>
  );
};

export default TransmissionDetailsList;
